<!--
	Last modified: 2023/03/02 14:58:54
-->
<script>
/*
	The option list is pretty lightweight, passing on some slot
	props from the dropdown button.
*/
import { _dropdownButtons } from './index.js';

export default {
	name: 'DropdownOptionList',
	functional: true,

	props: {
		id: {
			type: String,
			required: true,
		},
		forcedOwner: {
			type: Object,
			default: null,
		},
	},

	render(h, ctx) {
		const { props, scopedSlots } = ctx;
		const owner =
			props.forcedOwner ||
			[..._dropdownButtons].find((button) =>
				button.ariaOwns.split(' ').includes(props.id)
			);
		const isExpanded = owner?.isExpanded;
		const multiple = owner?.multiple;
		const slotBindings = owner?.slotBindings || {};
		owner?.updateListEvents?.(owner.ariaOwns);

		const {
			staticClass = '',
			class: dynamicClass = null,
			staticStyle = '',
			style: dynamicStyle = null,
			attrs = {},
			domProps,
			on = {},
			nativeOn,
		} = ctx.data;

		const bindings = {
			class: ['c-dropdown-option-list', staticClass, dynamicClass].filter(
				Boolean
			),
			style: [staticStyle, dynamicStyle].filter(Boolean),
			attrs: {
				id: props.id,
				role: 'listbox',
				tabindex: '-1',
				'aria-multiselectable': multiple ? 'true' : null,
				...attrs,
			},
			domProps,
			on,
			nativeOn,
		};

		return (
			process.client && (
				<ul v-show={isExpanded} {...bindings}>
					{scopedSlots.default?.(slotBindings) || scopedSlots.default}
				</ul>
			)
		);
	},
};
</script>

<style lang="postcss">
:where(.c-dropdown-option-list) {
	display: block;
	margin: 0;
	padding: 0;
}
</style>
