<!--
	Last modified: 2023/03/02 14:58:35
-->
<script>
/*
	We keep the options as lightweight as possible. They are not
	meant to contain any logic. They are just meant to be rendered
	and read.
*/
export default {
	name: 'DropdownOption',
	functional: true,

	props: {
		id: {
			type: String,
			required: true,
		},
	},

	render(h, ctx) {
		const { props, scopedSlots } = ctx;
		const {
			staticClass = '',
			class: dynamicClass = null,
			staticStyle = '',
			style: dynamicStyle = null,
			attrs = {},
			domProps,
			on = {},
			nativeOn,
		} = ctx.data;

		const bindings = {
			class: ['c-dropdown-option', staticClass, dynamicClass].filter(
				Boolean
			),
			style: [staticStyle, dynamicStyle].filter(Boolean),
			attrs: {
				id: props.id,
				role: 'option',
				tabindex: '-1',
				...attrs,
			},
			domProps,
			on,
			nativeOn,
		};

		return (
			process.client && (
				<li {...bindings}>
					{scopedSlots.default?.() || scopedSlots.default}
				</li>
			)
		);
	},
};
</script>

<style lang="postcss">
:where(.c-dropdown-option) {
	cursor: pointer;
}
</style>
