<!--
	Last modified: 2023/03/21 13:03:01
-->
<template>
	<button class="c-dropdown-button" v-bind="attrs" v-on="listeners">
		<slot v-bind="slotBindings"></slot>
	</button>
</template>

<script>
/*
	We try to keep as much as the logic within the button component,
	meaning this is the access point to the dropdown.
*/
import { _dropdownButtons } from './index.js';

export default {
	name: 'DropdownButton',
	inheritAttrs: false,

	model: {
		prop: 'value',
		event: 'change',
	},

	props: {
		// Bind a value from the outside
		value: {
			type: String,
			default: '',
		},

		// Required for both accessibility purposes and to join elements together
		ariaOwns: {
			type: String,
			required: true,
		},

		// Whether or not the dropdown can have multiple selected values
		multiple: {
			type: Boolean,
			default: false,
		},

		// Whether or not to close the dropdown when an option is selected
		closeOnSelect: {
			type: Boolean,
			default: true,
		},

		inititiallyExpanded: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isExpanded: this.inititiallyExpanded,
			selectedIds: [],
			selectedValues: (this.value?.split?.(',') ?? []).filter(Boolean),
			selectedTexts: [],
			activedescendant: '',
			hasChanged: false,
			isNavigatingByKeyboard: false,
		};
	},

	computed: {
		attrs() {
			return {
				type: 'button',
				role: 'combobox',
				'aria-haspopup': 'listbox',
				'aria-owns': this.ariaOwns,
				'aria-controls': this.ariaOwns,
				'aria-expanded': this.isExpanded?.toString(),
				'aria-activedescendant': this.activedescendant || null,
				value: this.selectedValues.join(','),
				...this.$attrs,
			};
		},

		listeners() {
			return {
				...this.$listeners,

				// Click
				click: (event) => {
					this.$listeners?.click?.(event);
					if (event.defaultPrevented) {
						return;
					}

					this.$el.focus({
						preventScroll: true,
					}); // Safari and Firefox need this, as they do not automatically focus on click
					this.toggleOwnedLists();
				},

				// Keyboard keys
				keydown: (event) => {
					this.$listeners?.keydown?.(event);
					if (event.defaultPrevented) {
						return;
					}

					// Escape
					if (event.key === 'Escape') {
						this.toggleOwnedLists(false);
						event.preventDefault();
						event.stopPropagation();
					}

					// Up
					if (event.key === 'ArrowUp') {
						this.onNavigation(event);
						event.preventDefault();
					}

					// Down
					if (event.key === 'ArrowDown') {
						this.onNavigation(event);
						event.preventDefault();
					}

					// Enter
					if (event.key === 'Enter') {
						this.onNavigation(event);
						event.preventDefault();
					}

					// Space
					if (event.key === ' ') {
						this.onNavigation(event);
						event.preventDefault();
					}
				},

				// Focusout (capture)
				'!focusout': (event) => {
					this.$listeners?.['!focusout']?.(event);
					if (event.defaultPrevented) {
						return;
					}

					this.onFocusout(event);
				},
			};
		},

		/* slotBindings are used here and in the DropdownOptionList component */
		slotBindings() {
			return {
				// Is the dropdown currently expanded or not?
				isExpanded: this.isExpanded,

				// An array of all the ids of the currently selected options
				selectedIds: this.selectedIds,

				// An array of all the values of the currently selected options
				selectedValues: this.selectedValues,

				// An array of all the texts of the currently selected options
				selectedTexts: this.selectedTexts,

				// The id of the currently active option (ie. "in focus")
				activedescendant: this.activedescendant,

				// Allows us to differentiate between keyboard and mouse navigation in our stylings
				isNavigatingByKeyboard: this.isNavigatingByKeyboard,
			};
		},
	},

	watch: {
		value(value) {
			this.selectedValues = value.split(',').filter(Boolean);
		},
		selectedValues(values) {
			// use values here instead
			const options = this.getOptionElements().filter((option) =>
				values?.includes?.(option.getAttribute('value'))
			);
			this.selectedIds = options
				.map((option) => option.id)
				.filter(Boolean);
			this.selectedTexts = options
				.map((option) => option.textContent?.trim?.())
				.filter(Boolean);
		},
		activedescendant(value) {
			this.$emit('update:activedescendant', value);
		},

		ariaOwns(val, oldVal) {
			this.updateListEvents(val, oldVal);
		},

		isExpanded: {
			immidiate: true,
			handler(value) {
				if (value) {
					// When opening the list we update the selected state of each option element
					const options = this.getOptionElements();
					options.forEach((option) => {
						if (
							this.selectedValues?.includes?.(
								option.getAttribute('value')
							)
						) {
							option.setAttribute('aria-selected', 'true');
						} else {
							option.removeAttribute('aria-selected');
						}
					});
				} else {
					this.activedescendant = '';
					this.isNavigatingByKeyboard = false;

					if (this.hasChanged) {
						this.$emit('change', this.selectedValues.join(','));
						this.hasChanged = false;
					}
				}
			},
		},
	},

	created() {
		if (process.client) {
			_dropdownButtons.add(this);
		}
	},

	mounted() {
		// When the list is ready we update the selected state of each option element
		const options = this.getOptionElements().filter((option) =>
			this.selectedValues?.includes?.(option.getAttribute('value'))
		);
		this.selectedIds = options.map((option) => option.id).filter(Boolean);
		this.selectedTexts = options
			.map((option) => option.textContent?.trim?.())
			.filter(Boolean);

		// And we add click events to every owned list
		this.updateListEvents(this.ariaOwns);
	},

	beforeDestroy() {
		_dropdownButtons.delete(this);

		// Remove click events from every owned list
		this.updateListEvents('', this.ariaOwns);
	},

	methods: {
		toggleOwnedLists(value = !this.isExpanded) {
			this.isExpanded = !!value;

			// Emit toggle events
			this.$emit('state:toggle', this.isExpanded);
			this.isExpanded && this.$emit('state:show');
			!this.isExpanded && this.$emit('state:hide');
		},

		getOptionElements() {
			const ownedLists =
				this.ariaOwns
					?.split?.(' ')
					?.map((id) => document.getElementById(id)) || [];
			const options = [];
			ownedLists.forEach((list) => {
				const optionElements =
					list?.querySelectorAll?.('.c-dropdown-option') || [];
				options.push(...optionElements);
			});
			return options;
		},

		updateInternalValue() {
			const options = this.getOptionElements();
			const selectedOptions = options.filter(
				(option) =>
					option.getAttribute('aria-selected')?.toLowerCase?.() ===
					'true'
			);
			const values = selectedOptions
				.map((option) => option.getAttribute('value'))
				.filter(Boolean);
			this.selectedValues = values.filter(Boolean);
			this.$emit('input', this.selectedValues.join(','));

			if (this.isExpanded) {
				this.hasChanged = true;
			} else {
				this.$emit('change', this.selectedValues.join(','));
				this.hasChanged = false;
			}
		},

		updateListEvents(val = '', oldVal = '') {
			const newList =
				val?.split?.(' ')?.map((id) => document.getElementById(id)) ||
				[];
			const oldList =
				oldVal
					?.split?.(' ')
					?.map((id) => document.getElementById(id)) || [];

			const addedElements = newList
				.filter((element) => !oldList?.includes?.(element))
				.filter(Boolean);
			const removedElements = oldList
				.filter((element) => !newList?.includes?.(element))
				.filter(Boolean);

			addedElements.forEach((list) => {
				list.addEventListener('click', this.onListClick);
			});
			removedElements.forEach((list) => {
				list.removeEventListener('click', this.onListClick);
			});
		},

		onNavigation(event) {
			// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/combobox_role#keyboard_interactions
			const options = this.getOptionElements();
			const currentIndex = this.activedescendant
				? options.findIndex(
						(option) => option.id === this.activedescendant
				  )
				: -1;
			this.isNavigatingByKeyboard = true;

			if (event.key === 'ArrowUp') {
				if (event.altKey) {
					// Close the list
					this.toggleOwnedLists(false);
				} else {
					// Move up the list
					this.toggleOwnedLists(true);
					let nextIndex = currentIndex - 1;
					if (nextIndex < 0) {
						nextIndex = options.length - 1;
					}
					this.activedescendant = options[nextIndex].id;

					// Scroll the active option into view
					const activeElement =
						this.activedescendant &&
						document.getElementById(this.activedescendant);
					try {
						activeElement?.scrollIntoView?.({
							block: 'nearest',
						});
					} catch (e) {
						// IE11 doesn't support smooth scrolling
						activeElement?.scrollIntoView?.();
					}
				}
			}

			if (event.key === 'ArrowDown') {
				if (event.altKey) {
					// Open the list
					this.toggleOwnedLists(true);
				} else {
					// Move down the list
					this.toggleOwnedLists(true);
					let nextIndex = currentIndex + 1;
					if (nextIndex >= options.length) {
						nextIndex = 0;
					}
					this.activedescendant = options[nextIndex].id;

					// Scroll the active option into view
					const activeElement =
						this.activedescendant &&
						document.getElementById(this.activedescendant);
					try {
						activeElement?.scrollIntoView?.({
							block: 'nearest',
						});
					} catch (e) {
						// IE11 doesn't support smooth scrolling
						activeElement?.scrollIntoView?.();
					}
				}
			}

			if (event.key === 'Enter' || event.key === ' ') {
				// When doing multi-select, enter opens and closes the list
				if (event.key === 'Enter' && this.multiple) {
					this.toggleOwnedLists();
					return;
				}

				if (this.isExpanded) {
					// Close the list
					if (!this.activedescendant) {
						this.toggleOwnedLists(false);
						return;
					}

					// Select the current option
					const currentOption = options[currentIndex];
					if (currentOption) {
						// Ignore if the option is disabled
						if (
							currentOption
								.getAttribute('aria-disabled')
								?.toLowerCase?.() === 'true'
						) {
							return;
						}

						// Toggle the elements selection state
						if (this.multiple) {
							if (
								currentOption
									.getAttribute('aria-selected')
									?.toLowerCase?.() === 'true'
							) {
								currentOption.removeAttribute('aria-selected');
							} else {
								currentOption.setAttribute(
									'aria-selected',
									'true'
								);
							}

							this.updateInternalValue();
							return;
						}

						// Select and close
						options.forEach((option) =>
							option.removeAttribute('aria-selected')
						);
						currentOption.setAttribute('aria-selected', 'true');

						this.updateInternalValue();

						if (this.closeOnSelect) {
							this.toggleOwnedLists(false);
						}
					}
				} else {
					// Open the list
					this.toggleOwnedLists(true);
				}
			}
		},

		onFocusout(event) {
			if (this.isExpanded) {
				const elements = [this.$el];
				this.ariaOwns?.split?.(' ')?.forEach((id) => {
					const element = document.getElementById(id);
					element && elements.push(element);
				});
				const focusContainer = elements.find((el) =>
					el.contains(event.relatedTarget)
				);
				if (!focusContainer) {
					this.toggleOwnedLists(false);
				} else if (focusContainer !== this.$el) {
					// Return focus to the button
					this.$el.focus({
						preventScroll: true,
					});
				}
			}
		},

		onListClick(event) {
			// Return focus to the button
			this.$el.focus({
				preventScroll: true,
			});

			this.isNavigatingByKeyboard = false;
			if (event.defaultPrevented) {
				return;
			}

			let { target } = event;
			while (target) {
				if (target.classList.contains('c-dropdown-option-list')) {
					break;
				}
				if (target.classList.contains('c-dropdown-option')) {
					// Ignore if the option is disabled
					if (
						target
							.getAttribute('aria-disabled')
							?.toLowerCase?.() === 'true'
					) {
						break;
					}

					// Toggle the elements selection state
					if (this.multiple) {
						if (
							target
								.getAttribute('aria-selected')
								?.toLowerCase?.() === 'true'
						) {
							target.removeAttribute('aria-selected');
						} else {
							target.setAttribute('aria-selected', 'true');
						}
						this.activedescendant = target.id;

						this.updateInternalValue();
						break;
					}

					// Select and close
					const options = this.getOptionElements();
					options.forEach((option) =>
						option.removeAttribute('aria-selected')
					);
					target.setAttribute('aria-selected', 'true');

					this.updateInternalValue();
					if (this.closeOnSelect) {
						this.toggleOwnedLists(false);
					}
					break;
				}
				target = target.parentElement;
			}
		},
	},
};
</script>
